<template>
  <TagbyDetailLayout>
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(adv)>
          test
        </template>
      </TagbyTabs>
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { useData } from './data'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTabs from '@/components/TagbyTabs.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
  },
  setup() {
    // const dataStore = useData()
    // const data = storeToRefs(dataStore)

    const tabList = [
      { key: 'adv', title: '사용자' },
      { key: 'biz', title: '사업자' },
      { key: 'caution', title: '경고' },
      { key: 'caution', title: '경고' },
    ]

    return {
      // adv: data.adv,
      tabList,
    }
  },
})
</script>
